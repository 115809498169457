import { h } from 'preact';
import { useRef, useEffect } from 'preact/compat';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => {

	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (!ref.current?.contains(event.target)) {
				closeNavMenu();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
	}, [ref]);

	const toggleNavMenu = () => {
		document.getElementById("nav-header")?.classList.toggle(style.open);
	}

	const closeNavMenu = () => {
		document.getElementById("nav-header")?.classList.remove(style.open);
	}

	return (
		<header id="nav-header" class={style.header} ref={ref}>
			<div class={style.navicon} onClick={toggleNavMenu} >
				<div class={style.lineone}></div>
				<div class={style.linetwo}></div>
				<div class={style.linethree}></div>
			</div>
			<nav>
				<Link activeClassName={style.active} onClick={closeNavMenu} href="/">Начало</Link>
				<Link activeClassName={style.active} onClick={closeNavMenu} href="/layout">Разпределение</Link>
				<Link activeClassName={style.active} onClick={closeNavMenu} href="/construction">Реализация</Link>
				<Link activeClassName={style.active} onClick={closeNavMenu} href="/location">Местоположение</Link>
				<Link activeClassName={style.active} onClick={closeNavMenu} href="/contacts">Контакти</Link>
			</nav>
		</header>
	);
}

export default Header;
