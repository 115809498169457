import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Construction from '../routes/construction';
import Layout from '../routes/layout';
import Location from '../routes/location';
import Contacts from '../routes/contacts';

const App = () => (
	<div id="app">
        <Header />
        <Router>
            <Route path="/" component={Home} />
            <Route path="/layout" component={Layout}/>
            <Route path="/layout/floor/:floorNumber" component={Layout}/>
            <Route path="/layout/:unitType/:unitNumber" component={Layout}/>
            <Route path="/construction/" component={Construction} />
            <Route path="/location/" component={Location} />
            <Route path="/contacts" component={Contacts} />
        </Router>
    </div>
);

export default App;
